/// <reference path="../../main.scss" />
// ------------- HTML CLASSES - PAGE TRANSITIONS ------------- //

html {
    @include animation($name: fade-in, $timing: ease-in, $duration: .3s);
}

body.no-scroll {
    overflow: visible;
    position: relative;
    height: 100%;


    @include media(tablet-larger) {
        position: relative;
        overflow: hidden;
    }
}
// ------------- VISIBILITY - SHOW/HIDE ------------- //
.svg-sprite {
    display: none;
}

.ng-cloak {
    visibility: hidden !important;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

// ------------- EPISERVER QUICK NAVIGATOR ------------- //
#epi-quickNavigator {
    li {
        padding-top: 0;
        text-indent: 0;

        &:before {
            content: none;
        }
    }

    a {
        border: none;
        padding: 0;
    }
}


// ------------- BACKGROUND THEME CLASSES ------------- //



.color-primary {
    background-color: $primary-color;

    .button-secondary {
        &:active,
        &:focus,
        &:hover {
            color: $primary-color;
        }
    }
}

.color-secondary {
    background-color: $blue;


    .button-secondary {
        &:hover {
            color: $blue;
        }
    }
}

.color-tertiary {
    background-color: $purple;


    .button-secondary {
        &:hover {
            color: $purple;
        }
    }
}

.color-quaternary {
    background-color: $offWhite;
    color: $black;


    .fiftyfifty-quote-attr {
        &:before {
            border-color: $black;
        }
    }
}

.color-quinary {
    background-color: $black;

    .button-secondary {
        &:hover {
            color: $black;
        }
    }
}

.color-yellow {
    background-color: $yellow;

    .button-secondary {
        &:hover {
            color: $yellow;
        }
    }
}

.color-fuschia {
    background-color: $fuschia;



    .button-secondary {
        &:hover {
            color: $fuschia;
        }
    }
}

.color-aqua {
    background-color: $aqua;


    .button-secondary {
        &:hover {
            color: $aqua;
        }
    }
}

.color-orange {
    background-color: $orange;


    .button-secondary {
        &:hover {
            color: $orange;
        }
    }
}

.color-green {
    background-color: $green;


    .button-secondary {
        &:hover {
            color: $green;
        }
    }
}

.color-invert {
    .button,
    .button-secondary {
        border-color: $offWhite;
        background: transparent;
        color: $offWhite;

        &:hover {
            background: $offWhite;
            border-color: $offWhite;
            color: $black;
        }
    }
}

.color-venue-orange {
    background-color: $venue-theme-orange;


    .button-secondary {
        &:hover {
            color: $venue-theme-orange;
        }
    }
}

.color-venue-blue {
    background-color: $venue-theme-blue;


    .button-secondary {
        &:hover {
            color: $venue-theme-blue;
        }
    }
}

.color-venue-green {
    background-color: $venue-theme-green;


    .button-secondary {
        &:hover {
            color: $venue-theme-green;
        }
    }
}

.color-primary,
.color-secondary,
.color-tertiary,
.color-invert,
.color-quinary,
.color-orange,
.color-green,
.color-yellow,
.color-fuschia,
.color-venue-blue,
.color-venue-green,
.color-venue-orange,
.color-aqua {
    color: $offWhite;



    .button-secondary {
        border-color: $offWhite;
        background: transparent;
        color: $offWhite;

        &:hover {
            background: $offWhite;
            border-color: $offWhite;
        }

        &:active,
        &:focus {
            background-color: $black;
            border-color: $black;
            color: $offWhite;
        }
    }
}

.venue-theme-orange {
    color: $venue-theme-orange;
}

.venue-theme-green {
    color: $venue-theme-green;
}

.venue-theme-blue {
    color: $venue-theme-blue;
}


.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &-red .overlay {
        background: rgba($red, 0.7);
    }

    &-blue .overlay {
        background: rgba($blue, 0.7);
    }

    &-purple .overlay {
        background: rgba($purple, 0.7);
    }

    &-orange .overlay {
        background: rgba($orange, 0.7);
    }

    &-yellow .overlay {
        background: rgba($yellow, 0.7);
    }

    &-green .overlay {
        background: rgba($green, 0.7);
    }

    &-fuschia .overlay {
        background: rgba($fuschia, 0.7);
    }

    &-aqua .overlay {
        background: rgba($aqua, 0.7);
    }

    &-red .background {
        background: $red;
    }

    &-blue .background {
        background: $blue;
    }

    &-purple .background {
        background: $purple;
    }

    &-orange .background {
        background: $orange;
    }

    &-yellow .background {
        background: $yellow;
    }

    &-green .background {
        background: $green;
    }

    &-fuschia .background {
        background: $fuschia;
    }

    &-aqua .background {
        background: $aqua;
    }
}
// ------------- BACKGROUND IMAGE COVER CLASSES ------------- //
.background-centercenter {
    background-position: center center;
}

.background-centertop {
    background-position: center top;
}

.background-centerbottom {
    background-position: center bottom;
}

.background-lefttop {
    background-position: left top;
}

.background-leftcenter {
    background-position: left center;
}

.background-leftbottom {
    background-position: left bottom;
}

.background-righttop {
    background-position: right top;
}

.background-rightcenter {
    background-position: right center;
}

.background-rightbottom {
    background-position: right bottom;
}

@include media(tablet-larger) {
    .mobile-background-centercenter {
        background-position: center center;
    }

    .mobile-background-centertop {
        background-position: center top;
    }

    .mobile-background-centerbottom {
        background-position: center bottom;
    }

    .mobile-background-lefttop {
        background-position: left top;
    }

    .mobile-background-leftcenter {
        background-position: left center;
    }

    .mobile-background-leftbottom {
        background-position: left bottom;
    }

    .mobile-background-righttop {
        background-position: right top;
    }

    .mobile-background-rightcenter {
        background-position: right center;
    }

    .mobile-background-rightbottom {
        background-position: right bottom;
    }
}
// ------------- EVENT TYPE TAGS ------------- //
.event-tag {
    &:before {
        content: '';
        background-repeat: no-repeat;
        display: inline-block;
        min-width: $spacer-primary/2;
        height: $spacer-primary/2;
        margin-right: $spacer/2;
    }
}

.tag-member {
    &:before {
        background-image: url('../static/images/svgs/member.svg');
    }

    &-inverted {
        &:before {
            background-image: url('../static/images/svgs/member-inverted.svg');
        }
    }
}

.tag-discount {
    &:before {
        background-image: url('../static/images/svgs/discount.svg');
    }

    &-inverted {
        &:before {
            background-image: url('../static/images/svgs/discount-inverted.svg');
        }
    }
}


.tag-talk {
    &:before {
        background-image: url('../static/images/svgs/pre-concert-talk.svg');
    }
}

.tag-livestream {
    &:before {
        background-image: url('../static/images/svgs/live-stream.svg');
    }
}

.tag-visiting {
    &:before {
        background-image: url('../static/images/svgs/visiting-presenter.svg');
    }
}

.tag-family {
    &:before {
        background-image: url('../static/images/svgs/family-matinee.svg');
    }
}

.tag-student {
    &:before {
        background-image: url('../static/images/svgs/student-discount.svg');
    }
}

.tag-soldout {
    &:before {
        background-image: url('../static/images/svgs/alert-square.svg');
    }
}

.tag-almostout {
    &:before {
        background-image: url('../static/images/svgs/alert-triangle.svg');
    }
}

.tag-dancefloor {
    &:before {
        background-image: url('../static/images/svgs/dance-floor.svg');
    }
}

.tag-liveandstreamed {
    &:before {
        background-image: url('../static/images/svgs/live-streamed.svg');
    }
}

.tag-soldout,
.tag-almostout {
    color: $primary-color;
}

.tag-dancefloor,
.tag-member,
.tag-discount {
    color: $purple;
}

.tag-talk,
.tag-livestream,
.tag-liveandstreamed,
.tag-visiting,
.tag-family,
.tag-student {
    color: $blue;
}
