﻿.ace-page {
    #registerControl.ace-profile {

        .addressManager {
            margin-bottom: $spacer-large;

            span {
                display: block;
                margin-bottom: $spacer;
            }

            a:not(.btn) {
                @extend %modal-link;
            }
        }

        .formSection ul {
            margin-left: 0;
        }

        h1 p {
            @extend %h1-page-heading;
        }

        .update-info input {
            margin-top: 0;
        }

        .billingAddressSection {
            margin-bottom: $spacer-large;
            margin-top: $spacer-large;
        }

        .shippingAddressList {
            margin: 0;

            li {
                margin-bottom: $spacer-primary/2;
            }

            span {
                margin-bottom: $spacer;
            }

            a {
                margin-right: $spacer;
            }
        }

        .passwordManager {
            margin-top: $spacer-primary;
            margin-bottom: $spacer-large;
        }
    }
}

@include media(small) {
    .ace-page {
        #registerControl.ace-profile {
            .addressManager {
                margin-bottom: $spacer-primary;
            }

            .billingAddressSection {
                margin-top: $spacer-primary;
                margin-bottom: $spacer-primary;
            }
        }
    }
}


