﻿/// <reference path="../../main.scss" />
$meganav-spacer: 40px;

.privacy-alert {
    &-inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        p {
            margin-bottom: $spacer;
        }

        a {
            color: $offWhite;
            border-bottom: 4px solid $offWhite;

            &:focus,
            &:active,
            &:hover {
                color: $black;
                background-color: $offWhite;
                border-color: $offWhite;
            }
        }
    }

    &-container {
        padding: $spacer-primary / 2;
        max-width: 70%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1000;
        color: $offWhite;
        animation: slideUp 2s 0s ease-in both;


        @include media(medium) {
            max-width: 70%;
        }

        @include media(small) {
            max-width: 100%;
        }

        &.hidden {
            display: none;
        }
    }
}


@keyframes slideUp {
    0% {
        bottom: -100%;
    }

    100% {
        bottom: 0;
    }
}
