/// <reference path="../../main.scss" />

%navigation-font {
    font-family: $font-accent;
    font-weight: 700;
    text-transform: uppercase;
}
// DESKTOP
.season-overview-navigation {
    position: relative;
    display: flex;
    max-width: 100vw;
    overflow: visible;
    background: $black;
    width: 100%;
    z-index: 9;
    transition: all $header-transition ease-in-out;
    font-family: $font-accent;
    position: sticky;
    top: var(--nav-clearance);

    &-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        margin-left: $spacer * 9;
        margin-right: $spacer * 9;
        height: $spacer * 9;
        flex: 1 1 auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background: transparent;
        }

        @include media-min(large-max) {
            overflow: hidden;
        }

        @include media(tablet-larger) {
            margin-left: $spacer * 6;
            margin-right: $spacer * 6;
            height: $spacer * 6;
        }
    }

    &-item {
        flex: 0 1 auto;
        padding: 0.75rem 0;
        min-width: calc(100% / 8);
        background: $black;
        color: $offWhite;
        font-size: 3.25rem;
        line-height: 0.75;
        cursor: pointer;
        @extend %navigation-font;

        &:active,
        &.active {
            color: $black;
            background: $offWhite;
        }

        &[disabled] {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

        &:hover {
            color: $offWhite;
            background: $primary-color;
        }

        @include media(large) {
            min-width: calc(100% / 7);
        }

        @include media(medium-larger) {
            min-width: calc(100% / 6);
        }

        @include media(tablet-larger) {
            font-size: 1.85rem;
        }

        @include media-min(large-max) {
            min-width: 0;
            flex: 1 1 auto;
        }
    }

    &-paddle {
        position: absolute;
        width: $spacer * 9;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        z-index: 10;

        &:focus {
            outline: 1px solid $primary-color;
        }

        @include media(tablet-larger) {
            width: $spacer * 6;
        }

        @include media-min(large-max) {
            display: none;
        }

        img {
            height: 100%;
            max-height: 100%;
            width: auto;
        }

        &[disabled] {
            opacity: 0.3;
            cursor: not-allowed;
            pointer-events: none;
            background: transparent;
        }

        &:hover {
            background: $primary-color;
        }


        &.paddle-left,
        &.paddle-right {
            background: white;

            &:hover {
                background: $primary-color;
            }

            @include media(tablet) {
                &:hover,
                &:active {
                    background: white;
                }
            }

            &.disabled {
                pointer-events: none;
                position: relative;

                &:after {
                    content: "";
                    background-image: url("../images/line-pattern-b-w-transparent_16x16.png");
                    background-color: rgba(0, 0, 0, 0.7);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }

        &.paddle-left {
            left: 0;
            top: 0;
            transform: rotate(180deg);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        &.paddle-right {
            right: 0;
            top: 0;
        }
    }
}
// MOBILE
.season-overview-navigation-mobile {
    display: block;
    width: 100%;

    &-container {
        display: flex;
    }

    .select-container {
        margin-top: 0;
        margin-bottom: 0;
        max-width: none;

        &:hover {
            background-color: $black;
        }

        &:after {
            color: $offWhite;
            background-color: $black;
            background: url("../static/images/svgs/dropdown-arrow-light.svg") no-repeat center;
            border-left: 2px solid $offWhite;
            background-size: 50%;
        }
    }

    .selectDropdown {
        max-width: none;
        background: bottom;
        color: $offWhite;
        background: $black;
        margin: 0;
        padding-left: $spacer-primary;
        font-size: 1.575rem;
        @extend %navigation-font;

        option {
            color: $black;

            &:active, &:selected {
                background: black;
                color: $offWhite;
            }

            &[disabled] {
                color: $gray;
            }
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $black;
            border-color: transparent;
        }
    }
}

// BASIC LAYOUT
.season-overview-navigation {
    &-desktop {
        display: flex;

        @include media(small) {
            display: none;
        }
    }

    &-mobile {
        display: none;

        @include media(small) {
            display: block;
        }
    }
}
