/// <reference path="../../main.scss" />
// ------------- RICH TEXT STYLES ------------- //


.rich-text {
    .two-column-container {
        display: flex;
        justify-content: space-between;
    }

    .two-column-item {
        width: 48%;
    }

    &-centered {
        max-width: $max-width-content;
        margin: 0 auto;
    }

    .primary-color {
        color: $primary-color;
    }

    .gray {
        color: $gray;
    }

    a {
        &.RT-button {
            @extend %button;
            border: none;
        }

        &.RT-button-secondary {
            @extend %button-secondary;
            border: none;
        }
    }


    .unlink {
        @extend %unlink;

        &:hover {
            color: $black;
        }

        & > img {
            @extend %unlink;
        }
    }

    span,
    em,
    strong {
        font-size: inherit;
        line-height: inherit;
    }

    .h4-primary {
        text-transform: none;
    }
}


[class*="theme"] .rich-text {
    .RT {
        &-button {

            a {

                @extend %button;
                border: none;


                &:hover {
                    border-color: $primary-color;
                }
            }
        }


        &-button-secondary {
            a {

                @extend %button-secondary;
                border: none;
            }
        }

        &-button,
        &-button-secondary {
            a {
                border-bottom: 0;
                padding: inherit;
                white-space: normal;

                &:focus,
                &:active,
                &:hover {
                    padding-top: 11px; //override link style for padding top
                }
            }
        }
    }
}

@include media(tablet) {
    .rich-text {
        .two-column-container {
            display: block;
        }

        .two-column-item {
            width: 100%;

            + .two-column-item {
                margin-top: $spacer-primary;
                display: block;
            }
        }
    }
}

@include media(small) {
    .rich-text {
        a {
            padding: 0 2px 2px 2px;
            border-bottom: 2px solid $black;

            &:hover,
            &:focus,
            &:active {
                padding-top: 4px; //height of bottom border and bottom padding. equal space top and bottom on hover
            }
        }
    }
}
