﻿/// <reference path="../../main.scss" />
$carousel-breakpoint: 'large';
$carousel-offset: 6rem;

.gallery-shelf {
    padding-bottom: $spacer-primary;

    & + & {
        margin-top: $spacer-primary;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $spacer;
        margin-bottom: $spacer;

        @include media(tablet-larger) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-title {
        font-family: $font-accent;
        font-weight: normal;
        margin: 0;
    }
    // Slide sizing
    &-slide {
        text-indent: 0;
        padding: 0;
        position: relative;
        width: calc(25% - #{$spacer});
        white-space: normal;
        border-bottom: none;

        @include media(large) {
            width: calc(40% - #{$spacer});
        }

        @include media(small) {
            width: calc(90% - #{$spacer});
        }

        &::before, &::after {
            content: none;
        }

        &:hover, &:focus, &:active {
            border: none;
            padding-top: 0;
            color: transparent;
        }
    }
    // Slide Content
    &-slide-inner {
        padding: 0;
        position: relative;
        padding-top: 9 / 16 * 100%;
        overflow: hidden;

        img {
            @extend %full-cover;
            object-fit: cover;
        }

        &-content {
            color: $offWhite;
            position: absolute;
            padding-left: $spacer-rem;
            padding-right: $spacer-rem;
            width: 95%;
            height: 100%;
            bottom: 10%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .title, .description {
                margin: 0;
            }
        }
    }
    // Popout section below each slide
    &-slide-feature {
        display: none;
        position: absolute;
        padding: $spacer-rem;
        color: $offWhite;
        width: 100%;

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-right: $spacer-md;
        }

        &-play {
            width: 20%;
            max-width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;

            &-icon {
                height: 35px;
                width: 35px;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('../static/images/svgs/play-button-outlined.svg');
            }
        }
    }

    .shelf-title, .shelf-description {
        margin: 0;
    }


    .swiper {
        position: relative;
        @extend %gutter-padding;
        padding-left: 0;
    }

    .swiper-wrapper {
        box-sizing: border-box;
        // This nonsense creates the space for hover effects
        padding-top: $carousel-offset;
        padding-bottom: $carousel-offset;
        margin-top: -$carousel-offset;
        margin-bottom: -$carousel-offset;
    }
}

// Carousel Navigation
.btn-arrows {
    margin: 0 auto;
    justify-content: flex-end;
    top: 0;
    position: absolute;
    display: flex;
    z-index: 9;
    right: $spacer-body-responsive;

    @include media(large) {
        right: $spacer-primary;
    }

    @include media(small) {
        right: $spacer-rem;
        top: -$spacer-rem;
    }

    &.hide {
        display: none;
    }
}

// Gallery Shelf Hover Effects
@include media-min(tablet) {
    .gallery-shelf {
        .gallery-shelf-slide:hover {
            transform: scale(1.1) translateY(-50px) perspective(1000px);
            transform-origin: top;
            z-index: 100;
            padding-top: 0;
            margin-left: $spacer-rem;
            // Force override of swiper gap
            margin-right: $spacer-rem * 2 !important;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            .overlay {
                display: none;
            }

            .gallery-shelf-slide-feature {
                display: flex;
            }

            .gallery-shelf-slide-inner {
                .shelf-title, .shelf-description {
                    display: none;
                }
            }
        }
    }
}
