﻿main > .epi-editContainer { //matching styles on site-spacing.scss for main
    padding-left: 0 !important;
    padding-right: 0 !important;

    > *:not(.full-width),
    .full-width-inner {
        padding-left: $spacer-body;
        padding-right: $spacer-body;
    }

    .pdp-banner-image {
        min-height: 400px;
    }

    .callout-fullwidth {
        height: 100%;
        min-height: 500px;

        &-heading span div {
            @extend %h1-page-heading;
            margin-bottom: 0;
        }

        p:empty {
            display: none;
        }

        &-inner {
            background-color: $offWhite;
            padding-top: $spacer-large;

            a {
                margin-top: $spacer-primary;
            }

            *:not(a) {
                text-transform: capitalize;
                color: $black;

                span {
                    background-color: transparent;
                    padding: 0;
                    color: $black;
                }
            }
        }
    }

    .callout-half {
        min-width: 0;
        min-height: 0;
    }

    .fiftyfifty-item {
        > .epi-editContainer {
            height: 100%;
        }
    }

    .owl-item {
        > .epi-editContainer {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .owl-stage {
        width: 100% !important;
        padding-left: $spacer-primary !important;
        padding-right: 0 !important;

        > .owl-item {
            width: 100% !important;

            .full-carousel-item {
                width: 31%;
                margin-bottom: $spacer-large;
                margin-left: 1%;
                margin-right: 1%;
            }
        }
    }

    .owl-carousel.series-carousel > .owl-stage-outer > .owl-stage > .owl-item {
        min-width: 100%;

        .full-carousel-item {
            width: 100%;
        }

        > .owl-stage-outer > .owl-stage {
            flex-wrap: wrap;

            > .owl-item {
                width: 31% !important;
                margin-bottom: $spacer-primary;
            }
        }
    }
}

.details-page-details-place {
    display: flex;
}

.details-page-details-genres {
    display: inline;
}

.details-page-details-genres + .epi-editContainer {
    display: inline;
}

.fiftyfifty-item > .epi-editContainer > .fiftyfifty {
    margin-top: 0;
}

.epi-preview,
.epi-editContainer {
    > .nospace { 
        margin-top: 0;
    }
}

@include media(x-large) {
    main > .epi-editContainer {
        > *:not(.full-width),
        .full-width-inner {
            padding-left: $spacer-body-responsive;
            padding-right: $spacer-body-responsive;
        }
    }
}

@include media(small) {
    main > .epi-editContainer {
        > *:not(.full-width),
        .full-width-inner {
            padding-left: $spacer*2;
            padding-right: $spacer*2;
        }
    }
}
