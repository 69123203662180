﻿/// <reference path="../../main.scss" />
$block-spacer: 29px;

.digital-page-content-area, .digital-page-content-wrapper {

    & > section:not(.full-width),
    & > div:not(.full-width) {
        padding-left: $spacer-body-responsive;
        padding-right: $spacer-body-responsive;

        @include media(large) {
            padding-left: $spacer-primary;
            padding-right: $spacer-primary;
        }

        @include media(small) {
            padding-left: $spacer-rem;
            padding-right: $spacer-rem;
        }
    }

    .callout-fullwidth {
        @include media(medium) {
            margin-bottom: $spacer-large;
        }
    }
}

.digital-page-content-area {
    padding-bottom: $spacer-large;
}
// Center accordion only for digital pages
.digital-page .accordion {
    margin-left: auto;
    margin-right: auto;
}

.digital-header {
    padding: $spacer-body-responsive 0;
    margin-bottom: $spacer-body-responsive;
    border-bottom: 4px solid $black;


    &-pretitle {
        @extend %h6;
        font-style: italic;
        margin-bottom: $spacer-rem;
    }

    &-title {
        @extend %h2-section-heading;
        margin-bottom: 0;
    }

    &-pretitle, &-title {
        margin-top: 0;
        max-width: $max-width-content;
    }

    &-subtitle {
        @extend %h5-paragraph-heading;
        margin-bottom: 0;
        max-width: $max-width-content;
    }
}

@include dark-theme() {

    .digital-header {
        border-bottom: 4px solid $offWhite;
    }
}
